import { useKbdCmdOrCtrlAndEnter } from '@/src/modules/keyboardShortcuts/useKbdCmdOrCtrlAndEnter';
import { DestinationButton } from '@/src/modules/resources/components/NewResource/components/DestinationButton';
import { useNewResourceContext } from '@/src/modules/resources/components/NewResource/context/ModalNewResourceContext';
import { Flex } from '@/src/modules/ui/components/Flex';
import { Kbd } from '@/src/modules/ui/components/Kbd';
import Modal from '@/src/modules/ui/components/Modal';
import { mediaMobile, mediaTablet } from '@/src/modules/ui/styled-utils';
import styled from 'styled-components';

const CancelButton = styled(Modal.CancelButton)`
  ${mediaTablet} {
    display: none;
  }
`;

const ConfirmButton = styled(Modal.Button)`
  ${mediaMobile} {
    width: 100px;
    border-radius: 7px;
    height: 38px;
    font-size: 16px;
    font-weight: 700;
    border: none;
    justify-content: center;
  }
`;

export const SubmitFooter: React.FC<{ submitDisabled: boolean; onSubmit: VoidFunction }> = ({
  submitDisabled,
  onSubmit,
}) => {
  const { handleClose } = useNewResourceContext();
  useKbdCmdOrCtrlAndEnter(onSubmit, { enabled: !submitDisabled });
  return (
    <Flex justifyContent="flex-end" gap={12}>
      <DestinationButton />
      <CancelButton onClick={handleClose}>
        Cancel
        <Kbd>
          <Kbd.IconEsc />
        </Kbd>
      </CancelButton>
      <ConfirmButton
        data-testid="modal-confirm-button"
        type="submit"
        disabled={submitDisabled}
        onClick={onSubmit}
      >
        Save
        <Kbd.Group>
          <Kbd.KbdCmdOrCtrl />+
          <Kbd>
            <Kbd.IconEnter />
          </Kbd>
        </Kbd.Group>
      </ConfirmButton>
    </Flex>
  );
};
