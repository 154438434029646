import { useShowExpandedFdoc } from '@/src/hooks/useExpandedFdoc';
import CheckCircleIcon from '@/src/icons/CheckCircleIcon';
import CrossCircleIcon from '@/src/icons/CrossCircleIcon';
import QueueCircleIcon from '@/src/icons/QueueCircleIcon';
import { IconProps } from '@/src/icons/types';
import UpdateIcon from '@/src/icons/UpdateIcon';
import useBulkUploaderStore, { FileUploadMetadata } from '@/src/lib/bulkUploader/store';
import { FileStatus, FileUploadErrorCodes, IUploadFile } from '@/src/lib/bulkUploader/uploader';
import { useQueryInbox } from '@/src/modules/connections/queries/useQueryInbox';
import { useQueryResourceRoot } from '@/src/modules/resource-roots/queries/useQueryResourceRoot';
import { useQueryFolder } from '@/src/modules/resources/queries/useQueryFolder';
import clsx from 'clsx';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useMemo } from 'react';
import { shallow } from 'zustand/shallow';
import styles from './BulkUploaderDisplayItem.module.scss';
import Progress from './Progress';

const UploadStateToIcon: {
  [key in FileStatus]: React.FC<IconProps>;
} = {
  [FileStatus.Ready]: QueueCircleIcon,
  [FileStatus.Retrying]: QueueCircleIcon,
  [FileStatus.Uploading]: UpdateIcon,
  [FileStatus.Finalizing]: UpdateIcon,
  [FileStatus.Success]: CheckCircleIcon,
  [FileStatus.Failed]: CrossCircleIcon,
};

const BulkUploaderDisplayItem: React.FC<{
  item: IUploadFile<FileUploadMetadata>;
  canInteract?: boolean;
}> = ({ item, canInteract }) => {
  const { file, progress } = item;
  const router = useRouter();
  const { inboxRoot } = useQueryInbox();
  const { folder } = useQueryFolder(item.metadata.parent.id);

  const { asLegacySpace: list } = useQueryResourceRoot(
    folder && folder?.id === folder?.list ? folder.id : undefined,
  );

  const showExpandedFdoc = useShowExpandedFdoc();

  const folderName = useMemo(() => {
    if (folder?.id === inboxRoot?.id) return 'Inbox';
    if (folder?.list && folder.list === folder.id) return list?.title;
    return folder?.data.name;
  }, [folder, inboxRoot?.id, list]);

  const { bulkUploader } = useBulkUploaderStore((state) => {
    return {
      bulkUploader: state.bulkUploader,
    };
  }, shallow);

  const viewLink = useMemo(() => {
    if (!folder) return null;

    if (folder.id === inboxRoot?.id) return '/saved';
    else if (folder.list && folder.list === folder.id) return `/spaces/${folder.id}`;
    else return `/folders/${folder.id}`;
  }, [folder, inboxRoot?.id]);

  const onInteract = () => {
    if (
      item.status === FileStatus.Failed &&
      (item.errorCode === FileUploadErrorCodes.FileTooLarge ||
        item.errorCode === FileUploadErrorCodes.QuotaExceeded)
    ) {
      router.push('/settings/account#plan');
      return;
    }

    if (item.metadata.fdocId) showExpandedFdoc(item.metadata.fdocId);

    switch (item.status) {
      case FileStatus.Success: {
        if (!viewLink) return;
        router.push(viewLink);
        break;
      }
      case FileStatus.Failed: {
        bulkUploader?.retryFile(item.id);
        break;
      }
      case FileStatus.Retrying:
      case FileStatus.Ready:
      case FileStatus.Uploading: {
        bulkUploader?.cancelUpload(item.id);
        break;
      }
    }
  };

  const interactText = useMemo(() => {
    if (
      item.status === FileStatus.Failed &&
      (item.errorCode === FileUploadErrorCodes.FileTooLarge ||
        item.errorCode === FileUploadErrorCodes.QuotaExceeded)
    ) {
      return 'Upgrade';
    }

    switch (item.status) {
      case FileStatus.Success:
        return 'View';
      case FileStatus.Failed:
        return 'Retry';
      case FileStatus.Ready:
      case FileStatus.Uploading:
      case FileStatus.Finalizing:
      case FileStatus.Retrying:
        return 'Cancel';
    }
  }, [item.errorCode, item.status]);

  const Icon = UploadStateToIcon[item.status];

  return (
    <div key={item.id} className={styles.item}>
      <div
        className={clsx(styles.file__icon, {
          [styles.file__icon__success]: item.status === FileStatus.Success,
          [styles.file__icon__failed]: item.status === FileStatus.Failed,
          [styles.file__icon__uploading]:
            item.status === FileStatus.Uploading || item.status === FileStatus.Finalizing,
          [styles.file__icon__ready]: item.status === FileStatus.Ready,
        })}
      >
        <Icon size={18} />
      </div>
      <div className={styles.file__info}>
        <div className={styles.file__info__left}>
          <span className={clsx(styles.file__info__name)}>{file.name}</span>

          <small>
            {item.failureReason}
            {item.status === FileStatus.Failed &&
              item.errorCode === FileUploadErrorCodes.FileTooLarge && (
                <>
                  {' '}
                  <Link href="/settings/account#plan">Upgrade for unlimited upload size.</Link>
                </>
              )}
            {item.status === FileStatus.Success && (
              <>
                {viewLink && folderName ? 'Uploaded to ' : 'Uploaded'}
                {viewLink && folderName && <Link href={viewLink}>{folderName}</Link>}
              </>
            )}
            {item.status === FileStatus.Ready && 'In queue...'}
            {item.status === FileStatus.Retrying && 'Retrying, in queue...'}
            {item.status === FileStatus.Uploading && 'Uploading...'}
            {item.status === FileStatus.Finalizing && 'Finalizing...'}
          </small>
        </div>

        {item.status === FileStatus.Uploading && <Progress progress={progress} />}
      </div>
      {canInteract && (
        <div className={styles.file__action}>
          <button
            onClick={onInteract}
            className={styles.action_button}
            disabled={item.status === FileStatus.Finalizing}
          >
            {interactText}
          </button>
        </div>
      )}
    </div>
  );
};

export default BulkUploaderDisplayItem;
