import { Header } from '@/src/modules/resources/components/NewResource/components/Header';
import {
  TextAreaContainer,
  TextAreaInput,
} from '@/src/modules/resources/components/NewResource/components/TextArea';
import { useFormik } from 'formik';
import React from 'react';
import { shallow } from 'zustand/shallow';

import OverSizedInfo from '@/src/components/OverSizedInfo/OverSizedInfo';
import { useResponsive } from '@/src/hooks/responsive';
import { useUnmount } from '@/src/hooks/useUnmount';
import useBulkUploaderStore from '@/src/lib/bulkUploader/store';
import { MagicSuggestions } from '@/src/modules/magic/components/MagicSuggestions';
import { MagicSuggestionsContextProvider } from '@/src/modules/magic/components/MagicSuggestionsContext';
import { SuggestionContext } from '@/src/modules/magic/magic.types';
import { SubmitFooter } from '@/src/modules/resources/components/NewResource/components/SubmitFooter';
import {
  NewResourceType,
  NewResourceTypesConfig,
} from '@/src/modules/resources/components/NewResource/config';
import { useNewResourceContext } from '@/src/modules/resources/components/NewResource/context/ModalNewResourceContext';
import { FileUpload } from '@/src/modules/resources/components/NewResource/ModalContentUpload/FileUpload';
import { InputField } from '@/src/modules/ui/components/Field';
import { Flex } from '@/src/modules/ui/components/Flex';
import useFileUploadStore from '@/src/store/fileUploadStore';
import { UploadableFile } from '@/src/types/file';
import { generateKind } from '@/src/utils/files';
import { formatDiskSpace } from '@/src/utils/number';
import { prettifyForLLM } from '@/src/utils/text';
import TooDeepInfo from '@/src/views/FdocNew/OverSizedInfo';
import dayjs from 'dayjs';
import * as yup from 'yup';

const MAX_COMMENT_LENGTH = 25000;

const validationSchema = yup.object({
  comment: yup.string().max(MAX_COMMENT_LENGTH, 'Comment is too long'),
});

const generateFileInfo = (uploadableFile: UploadableFile) => {
  const file = uploadableFile.file;
  const extension = file.name.split('.').pop();

  return {
    name: file.name,
    size: formatDiskSpace(file.size),
    lastModified: dayjs(file.lastModified).toISOString(),
    path: uploadableFile.metadata.path,
    kind: extension ? generateKind(file.type, extension) : null,
  };
};

export const ModalContentUpload: React.FC = () => {
  const { isMobileView } = useResponsive();
  const { destinationParent, selectedTags, handleClose, onSelectSuggestion } =
    useNewResourceContext();
  const bulkUploader = useBulkUploaderStore((state) => state.bulkUploader, shallow);

  const { files, tooDeep, tooBigFiles, clearFiles } = useFileUploadStore((state) => {
    return {
      files: state.files,
      tooBigFiles: state.tooBigFiles,
      tooDeep: state.tooDeep,
      clearFiles: state.clearFiles,
    };
  }, shallow);

  const formik = useFormik({
    initialValues: {
      comment: '',
    },
    validationSchema,
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: async ({ comment }, formik) => {
      bulkUploader?.addFiles(
        files.map((file) => ({
          file: file.file,
          metadata: {
            path: file.metadata.path,
            comment,
            tags: selectedTags,
            parent: destinationParent!,
          },
        })),
      );

      formik.setSubmitting(false);
      handleClose();
    },
  });

  useUnmount(clearFiles, true);

  const titleOptions = NewResourceTypesConfig[NewResourceType.Upload];

  const suggestionsContext: SuggestionContext | undefined =
    files && files.length > 0
      ? {
          name: files.length === 1 ? files[0].file.name : undefined,
          comment: formik.values.comment,
          otherInfo: prettifyForLLM(
            files.length > 1
              ? {
                  totalFiles: files.length,
                  fileInfo: files.map(generateFileInfo),
                }
              : generateFileInfo(files[0]),
          ),
        }
      : undefined;

  const commentTextAreaRef = React.useRef<HTMLTextAreaElement>(null);

  return (
    <MagicSuggestionsContextProvider
      onSelectSuggestion={onSelectSuggestion}
      context={suggestionsContext}
    >
      <Flex flexGrow gap={24} direction="column" style={{ padding: '1rem' }} as="form">
        <Header
          icon={<titleOptions.icon style={titleOptions.iconStyle} />}
          label={titleOptions.label}
          minLabel={titleOptions.minLabel}
        />
        <Flex direction="column" gap="elementsContainer" flexGrow>
          <FileUpload
            onSelectFiles={(files) => {
              if (files.length > 0) {
                commentTextAreaRef.current?.focus();
              }
            }}
          />

          {tooDeep && tooBigFiles === 0 && !isMobileView && <TooDeepInfo />}
          {tooBigFiles > 0 && !isMobileView && (
            <OverSizedInfo amount={tooBigFiles} onClose={handleClose} />
          )}

          <InputField gap="sm" style={{ flexGrow: 1 }}>
            <TextAreaContainer>
              <TextAreaInput
                name="comment"
                value={formik.values.comment}
                onChange={formik.handleChange}
                placeholder="Say something about it... (optional)"
                ref={commentTextAreaRef}
                /**
                 * autofocus on mount when there are already files
                 * e.g. when user drag drops, or app receives intent of shared items
                 */
                autoFocus={files.length > 0}
              />
              <MagicSuggestions
                style={{
                  paddingBottom: 13,
                }}
              />
            </TextAreaContainer>
            <InputField.ErrorMessage message={formik.errors.comment} />
          </InputField>
        </Flex>
        <SubmitFooter
          submitDisabled={files.length === 0 || formik.isSubmitting || !formik.isValid}
          onSubmit={formik.handleSubmit}
        />
      </Flex>
    </MagicSuggestionsContextProvider>
  );
};
